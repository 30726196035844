import * as React from "react"
import "./statistics.scss"

const StatisticsComponent = ({data}) => {
    return (
        <section className="section-regular section-shadow-top bg-white pb-0 statistics-component">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 position-relative">
                        <div className="unusually-rapid mb-30 pt-30">
                            <h2>Unusually Rapid</h2>
                            <h5>Intelligent RPA vs. Competitive Solutions</h5>
                            <div className="counter-wrap mb-30">
                                <div className="counter-title text-uppercase">Interrogation & Analysis</div>
                                <div className="counter pink-counter">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width:`95%`}}
                                             aria-valuenow="100" aria-valuemin="0" aria-valuemax="95" aria-label="Modios Interrogation & Analysis"></div>
                                    </div>
                                </div>
                                <div className="counter gray-counter">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width:`30%`}}
                                             aria-valuenow="100" aria-valuemin="0" aria-valuemax="30" aria-label="Competitor Interrogation & Analysis"></div>
                                    </div>
                                </div>
                                <div className="counter-title text-uppercase">APPLICATION & WORKFLOWS</div>
                                <div className="counter perple-counter">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width:`80%`}}
                                             aria-valuenow="100" aria-valuemin="0" aria-valuemax="80" aria-label="Modios Application & Workflows"></div>
                                    </div>
                                </div>
                                <div className="counter gray-counter">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width:`40%`}}
                                             aria-valuenow="100" aria-valuemin="0" aria-valuemax="40" aria-label="Competitor Application & Workflows"></div>
                                    </div>
                                </div>
                                <div className="counter-title text-uppercase">VALIDATION & TESTING</div>
                                <div className="counter blue-counter">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width:`100%`}}
                                             aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" aria-label="Modios Validation & Testing"></div>
                                    </div>
                                </div>
                                <div className="counter gray-counter">
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width:`25%`}}
                                             aria-valuenow="100" aria-valuemin="0" aria-valuemax="25" aria-label="Competitor Validation & Testing"></div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="https://assets.cordiance.com/craft/modios-tax-solution-color-key.jpg" width="326" height="79" alt="" />
                            </div>
                        </div>
                        <div className="text-center">
                            <img src="https://assets.cordiance.com/craft/modios-mascot-presenting-upwards-half.png" width="400" height="359" id="modios-robot" alt="" style={{left: 0, bottom: 0}} />
                        </div>
                    </div>
                    <div className="col-md-8 m-lg-auto">
                        <h3 className="text-center pt-30">Balancing Tax Expertise<br />with Intelligent Technologies</h3>
                        <div className="row  align-items-center">
                            <div className="col-6">
                                <div className="row ptb-30 progress-circle">
                                    <div className="col-12 col-md-6 text-center text-md-end">
                                        <p>Modios Powered Testing</p>
                                    </div>
                                    <div className="col-12 col-md-6 text-center">
                                        <div className="circle_percent modios-powered percent_more" data-percent="95">
                                            <div className="circle_inner">
                                                <div className="round_per" style={{transform: `rotate(${95 * 3.6 + 180}deg)`}}></div>
                                            </div>
                                            <div className="circle_inbox"><span className="percent_text">95%</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row ptb-30 progress-circle">
                                    <div className="col-12 col-md-6 text-center">
                                        <div className="circle_percent expert-validation" data-percent="5">
                                            <div className="circle_inner">
                                                <div className="round_per" style={{transform: `rotate(${5 * 3.6 + 180}deg)`}}></div>
                                            </div>
                                            <div className="circle_inbox"><span className="percent_text">5%</span></div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 text-center text-md-start">
                                        <p>Expert Validation & Approval</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row ptb-30 progress-circle">
                                    <div className="col-12 col-md-6 text-center text-md-end">
                                        <p>Time Savings from Modios</p>
                                    </div>
                                    <div className="col-12 col-md-6 text-center">
                                        <div className="circle_percent time-savings percent_more" data-percent="75">
                                            <div className="circle_inner">
                                                <div className="round_per" style={{transform: `rotate(${75 * 3.6 + 180}deg)`}}></div>
                                            </div>
                                            <div className="circle_inbox"><span className="percent_text">75%</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row ptb-30 progress-circle">
                                    <div className="col-12 col-md-6 text-center">
                                        <div className="circle_percent cost-savings percent_more" data-percent="70">
                                            <div className="circle_inner">
                                                <div className="round_per" style={{transform: `rotate(${70 * 3.6 + 180}deg)`}}></div>
                                            </div>
                                            <div className="circle_inbox"><span className="percent_text">70%</span></div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 text-center text-md-start">
                                        <p>Cost Savings from Modios</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row ptb-30 progress-circle">
                                    <div className="col-12 col-md-6 text-center text-md-end">
                                        <p>Modios Delivered Work</p>
                                    </div>
                                    <div className="col-12 col-md-6 text-center">
                                        <div className="circle_percent cost-savings percent_more" data-percent="80">
                                            <div className="circle_inner">
                                                <div className="round_per" style={{transform: `rotate(${80 * 3.6 + 180}deg)`}}></div>
                                            </div>
                                            <div className="circle_inbox"><span className="percent_text">80%</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row ptb-30 progress-circle">
                                    <div className="col-12 col-md-6 text-center">
                                        <div className="circle_percent expert-delivered" data-percent="20">
                                            <div className="circle_inner">
                                                <div className="round_per" style={{transform: `rotate(${20 * 3.6 + 180}deg)`}}></div>
                                            </div>
                                            <div className="circle_inbox"><span className="percent_text">20%</span></div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 text-center text-md-start">
                                        <p>Expert Delivered Work</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StatisticsComponent