import * as React from "react"
const SubscribeComponent = ({data}) => {
    return (
        <section className="section-regular page-hero-section-bg page-hero-section-bg-scale">
            <div className="container">
                <div className="row ptb-30">
                    <div className="col-lg-12 text-left p-0 mobile-py-20">
                        <div className="box-with-border bg-white border border-1 border-dark">
                            <h2>SUBSCRIBE FOR UPDATES</h2>
                            <p className="m-0">Ready to stay on top of the latest and greatest in tax technology
                                with Modios? Sign up now! We promise we won't sell your information to anyone and
                                we'll keep our communications to a minimum with only the most important stuff.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubscribeComponent