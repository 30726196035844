import React, {useState} from 'react';
import axios from "axios"
import parse from "html-react-parser"

const ContactUsFormComponent = ({data}) => {

    axios.defaults.headers.post['Content-Type'] = 'application/json'

    const [isFormSubmitted, setFormSubmitted] = useState(false)

    const contactFormSubmit = (e) => {
        e.preventDefault()

        let data = {
            name: e.target.elements.name.value,
            company: e.target.elements.company.value,
            phone: e.target.elements.phone.value,
            email: e.target.elements.email.value,
            comment: e.target.elements.comment.value
        }

        axios.post('https://xx7mwsowbziighlfzv2ghgoh3e0pxkks.lambda-url.us-west-2.on.aws/', data)
            .then(result => {
                setFormSubmitted(true)
                console.log('sent contact request')
            })
    }

    return (
        <section className="position-relative contact-section pt-90 pb-100"
                 style={{backgroundImage: (data.contactUsFormBackground && data.contactUsFormBackground.length > 0) ? `url(${data.contactUsFormBackground[0].url})` : ""}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3 className="color-white"><i>{data.contactUsFormSectionTitle}</i></h3>
                    </div>
                </div>
                <div className="row pt-60">
                    <div className="col-lg-6 col-sm-6">
                        <div className="contact_info_inner text-white">
                            {parse(data.contactUsFormContent)}
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <h4 className="color-white">{data.contactUsFormTitle}</h4>

                        {isFormSubmitted ? (
                            <h5 className='color-pink'>Thank you for contacting Cordiance. We will be in touch shortly.</h5>
                        ):(
                            <form method="post" onSubmit={contactFormSubmit}>
                                <div className="form-container">
                                    <div className="form-field form-field-name">
                                        <label htmlFor="name">Name <span>*</span></label>
                                        <input type="text" className="input-field" name="name" id="name" required={true} />
                                    </div>
                                    <div className="form-field form-field-text">
                                        <label htmlFor="company">Company <span>*</span></label>
                                        <input type="text" className="input-field" name="company" id="company" required={true} />
                                    </div>
                                    <div className="form-field form-field-phone">
                                        <label htmlFor="phone">Phone</label>
                                        <input type="phone" className="input-field" name="phone" id="phone" required={false}/>
                                    </div>
                                    <div className="form-field form-field-email">
                                        <label htmlFor="email">Email <span>*</span></label>
                                        <input type="email" className="input-field" name="email" id="email" required={true}/>
                                    </div>
                                    <div className="form-textarea form-field-textarea">
                                        <label htmlFor="comment">Comment or Message <span>*</span></label>
                                        <textarea className="input-field" name="comment" id="comment" required={true}></textarea>
                                    </div>
                                </div>
                                <div className="form-submit-container">
                                    <button type="submit" name="submit">Submit</button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUsFormComponent