import * as React from "react"
import { formatDate } from "../helpers/date";
import { StaticQuery, graphql } from "gatsby"
import parse from "html-react-parser";

const BlogPostsComponent = ({data}) => {

    const getBlogPosts = (blogPosts) => {
        if (blogPosts.nodes.length > 0) {
            return blogPosts.nodes.map(blogPost => (
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="press-box">
                        <a href={`/${blogPost.uri}`} className="text-black">
                            <h4>{blogPost.title}</h4>
                        </a>
                        <p className="post-meta"><span className="published">{formatDate(blogPost.postDate)}</span></p>
                        <div className="post-content">
                            <div className="post-content-inner">
                                <p>{blogPost.blogPostPreviewText}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        } else {
            return (
                <span className="text-center color-white">{parse(data.blogPostsEmpty)}</span>
            )
        }
    }

    return (
        <StaticQuery
            query={graphql`{
                        blogPosts: allCraftBlogPostsDefaultEntry {
                            nodes {
                                key: uid
                                title
                                uri
                                postDate
                                blogPostPreviewText
                            }
                        }
                    }`}
            render={staticData => (
                <section className="position-relative parallax-bg pt-100 pb-25 press-events-section" style={{backgroundImage: (data.blogPostsBackground && data.blogPostsBackground.length > 0) ? `url(${data.blogPostsBackground[0].url})` : ""}}>
                    <div className="container">
                        <div className="row g-5">
                            {getBlogPosts(staticData.blogPosts)}
                        </div>
                    </div>
                </section>
            )}
        />
    )
}

export default BlogPostsComponent