import * as React from "react"
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"

import 'swiper/swiper.css'

const getLogoSlides = (images) => {
    return images.map(image => (
        <SwiperSlide key={image.key}>
            <div className="text-center d-flex flex-column align-items-center ">
                    <span className="swiper-items">
                        <img src={image.url} alt={image.alt} width="130" height="130" />
                    </span>
            </div>
        </SwiperSlide>
    ))
}
const LogoScrollerComponent = ({data}) => {
    return (
        <section id="swiper" className="slider-logo">
            <div className="swiper-slider-wrapper">
                <Swiper
                    modules={[Autoplay]}
                    direction={'horizontal'}
                    spaceBetween={30}
                    loop={true}
                    grabCursor={true}
                    autoplay={true}
                    speed={2000}
                    breakpoints={{
                        991: {slidesPerView: 17},
                        768: {slidesPerView: 5},
                        1:{slidesPerView: 3}
                    }}
                >
                    { getLogoSlides(data.logoScrollerImages) }
                </Swiper>
            </div>
        </section>
    )
}

export default LogoScrollerComponent