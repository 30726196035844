import React, {useState} from "react"
import parse from "html-react-parser"

const TabsComponent = ({data}) => {

    const [activeTab, setActiveTab] = useState(data.tabsList[0].key)

    const getTabControls = (tabs) => {
        return tabs.map(tab => {
            return (
                <a key={tab.key}
                   role="button"
                   className={`tabs-item-button text-black ${activeTab === tab.key ? 'active': ''}`}
                   onMouseEnter={() => setActiveTab(tab.key)}>
                    {tab.tabsListTitle}
                </a>
            )
        })
    }

    const getTabContent = (tabs) => {
        return tabs.map(tab => (
            <div className={`tabs-single-content ${activeTab !== tab.key ? 'd-none' : ''}`}>
                {parse(tab.tabsListContent)}
            </div>
        ))
    }

    return (
        <section className="section-regular parallax-bg" style={{backgroundImage: (data.tabsBackground && data.tabsBackground.length > 0) ? `url(${data.tabsBackground[0].url})` : ""}}>
            <div className="container">

                {data.tabsContentBefore &&
                    <div className="row">
                        <div className="col-md-12 text-center button-anchor responsive-iframe">
                            {parse(data.tabsContentBefore)}
                        </div>
                    </div>
                }

                <div className="row pt-30">
                    <div className="col-lg-10 text-center m-auto">
                        <div className="tab-wrapper">
                            <div className="tabs-controls d-flex align-items-center justify-content-center">
                                {getTabControls(data.tabsList)}
                            </div>
                        </div>
                        <div className="tabs-content button-anchor responsive-iframe">
                            {getTabContent(data.tabsList)}
                        </div>
                    </div>
                </div>

                {data.tabsContentAfter &&
                    <div className="row">
                        <div className="col-md-12 text-center button-anchor responsive-iframe">
                            {parse(data.tabsContentAfter)}
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default TabsComponent