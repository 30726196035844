import * as React from "react"

const TwoColumnProductSectionLinksComponent = ({data}) => {
    return (
        <section className="bigcard-box-section">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-6 p-0">
                        <div className="bigcard-box d-flex flex-column bg-darkbg position-relative  text-center parallax-bg">
                            <div className="bigcard-box-inner">
                                <div className="big-card-img">
                                    <img src="images/modios-rapid-testing-icon-lt.png" alt="modios-rapid-testing-icon-lt" />
                                </div>
                                <div className="big-card-img-small">
                                    <img src="images/modios-nextgen-dk-small.png" alt="modios-rapid-testing-icon-lt" />
                                </div>
                                <div className="bigcard-box-content">
                                    <h2>Rapid Testing</h2>
                                    <p>Thoroughly test tax engine configurations rapidly and accurately yielding
                                        complete
                                        and clear documented results.</p>
                                    <a className="btn btn-primary" href="rapid-testing.html">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 p-0">
                        <div className="bigcard-box d-flex flex-column bg-darkbg position-relative text-center parallax-bg2">
                            <div className="bigcard-box-inner with-iframe">
                                <div className="fluid-width-video-wrapper">
                                    <iframe title="Product Information" src="https://www.youtube-nocookie.com/embed/S3b9wUipLNg"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default TwoColumnProductSectionLinksComponent