import * as React from "react"
import parse from "html-react-parser"

const FlipFeatureListComponent = ({data}) => {

    const getFeatures = (featureItems) => {
        return featureItems.map((item) => (
            <div key={item.key} className="col card-col">
                <div className="card">
                    <div className="front">
                        <div className="content">
                            <div className="icon mb-30">
                                <i className={item.flipFeaturesListItemsIcon}></i>
                            </div>
                            {item.flipFeaturesListItemsTitle && <h3 className="card-heading">{item.flipFeaturesListItemsTitle}</h3>}
                        </div>
                    </div>
                    {item.flipFeaturesListItemsImage && item.flipFeaturesListItemsImage.length > 0
                        ?
                        <div className="back" style={{backgroundImage: `url(${item.flipFeaturesListItemsImage[0].url})`}}></div>
                        :
                        <div className="back">
                            <div className="content">
                                {item.flipFeaturesListItemsContent && parse(item.flipFeaturesListItemsContent)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        ))
    }

    switch(data.flipFeaturesListStyle) {
        case "style1":
            return (
                <section className="position-relative flip-box-section parallax-bg" style={{backgroundImage: (data.flipFeaturesListBackground && data.flipFeaturesListBackground.length > 0) ? `url(${data.flipFeaturesListBackground[0].url})` : ""}}>
                    <div className="section-regular">
                        <div className="container">
                            <div className="row ptb-30">
                                <div className="col-12 text-center text-white button-anchor">
                                    {data.flipFeaturesListContentBefore && parse(data.flipFeaturesListContentBefore)}
                                </div>
                            </div>
                            <div className="row ptb-30">
                                {getFeatures(data.flipFeaturesListItems)}
                            </div>
                            <div className="row ptb-30">
                                <div className="col-12 text-center text-white button-anchor">
                                    {data.flipFeaturesListContentAfter && parse(data.flipFeaturesListContentAfter)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        case "style2":
            return (
                <section className="bg-dark position-relative  flip-box-section parallax-bg" style={{backgroundImage: (data.flipFeaturesListBackground && data.flipFeaturesListBackground.length > 0) ? `url(${data.flipFeaturesListBackground[0].url})` : ""}}>
                    <div className="section-regular">
                        <div className="container">
                            <div className="row ptb-30">
                                <div className="col-12 text-center text-white button-anchor">
                                    {data.flipFeaturesListContentBefore && parse(data.flipFeaturesListContentBefore)}
                                </div>
                            </div>
                            <div className="row ptb-30">
                                {getFeatures(data.flipFeaturesListItems)}
                            </div>
                            <div className="row ptb-30">
                                <div className="col-12 text-center text-white button-anchor">
                                    {data.flipFeaturesListContentAfter && parse(data.flipFeaturesListContentAfter)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        default:
            return <></>
    }
}

export default FlipFeatureListComponent