import * as React from "react"
import parse from "html-react-parser"

const DividerComponent = ({data}) => {
    switch(data.dividerStyle) {
        case "style1":
            return (
                <section className="pb-100 bg-dark position-relative parallax-bg divider-component style-1" style={{backgroundImage: (data.dividerBackground && data.dividerBackground.length > 0) ? `url(${data.dividerBackground[0].url})` : ""}}>
                    <div className="container pt-100">
                        <div className="row pt-50">
                            <div className="col-12 text-center em-pink">
                                <h2>{data.dividerTitle && parse(data.dividerTitle)}</h2>
                                <span className="subhead mb-30">{data.dividerSubtitle && parse(data.dividerSubtitle)}</span>
                            </div>
                        </div>
                    </div>
                </section>
            )
        case "style2":
            return (
                <section className="pb-100 pt-100 bg-dark position-relative parallax-bg divider-component style-2" style={{backgroundImage: (data.dividerBackground && data.dividerBackground.length > 0) ? `url(${data.dividerBackground[0].url})` : ""}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="et_pb_text_inner">
                                    <h6 className="sub-heading">{data.dividerTitle && parse(data.dividerTitle)}</h6>
                                    <h3>{data.dividerSubtitle && parse(data.dividerSubtitle)}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        default:
            return <></>
    }
}

export default DividerComponent