import * as React from "react"
import parse from "html-react-parser"
import "./centeredContent.scss"

const CenteredContentComponent = ({data}) => {
    return (
        <section className="page-hero-section section-regular bg-white page-hero-section-bg centered-content"
                 style={{
                     backgroundPosition: (data.centeredContentBgAlignment) ? 'center top' : 'center bottom',
                     backgroundImage: (data.centeredContentBgImage && data.centeredContentBgImage.length > 0) ? `url(${data.centeredContentBgImage[0].url})` : ''
                 }}>
            <div className="container">
                <div className="row ptb-30">
                    <div className="col-12 text-center button-anchor responsive-iframe">
                        {data.centeredContentBefore && parse(data.centeredContentBefore)}
                    </div>
                </div>
                <div className="row ptb-30">
                    <div className={`${data.centeredContentColumns === '1Column' ? 'text-center' : 'text-left'} col-12 m-auto button-anchor responsive-iframe`}>
                        <div className="hero-section-content text-center">
                            {data.centeredContentTitle &&
                                <h2>{parse(data.centeredContentTitle)}</h2>
                            }
                            {data.centeredContentSubtitle &&
                                <h3>{parse(data.centeredContentSubtitle)}</h3>
                            }
                        </div>
                        <div className={`${data.centeredContentColumns === '1Column' ? 'one-column-text' : 'two-column-text'}`}>
                            {data.centeredContentBody && parse(data.centeredContentBody)}
                        </div>
                    </div>
                </div>
                <div className="row ptb-30">
                    <div className="col-12 text-center button-anchor responsive-iframe">
                        {data.centeredContentAfter && parse(data.centeredContentAfter)}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CenteredContentComponent