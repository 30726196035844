import * as React from "react"
import CountUp from 'react-countup';

const getCounters = (counters) => {
    return counters.map(counter => (
        <div className="col-lg-4">
            <div className="percent-col color-white">
                <span className="percent-value d-block">
                    <CountUp start={0} end={counter.countersListCount} duration={3} />
                </span>
                <h3 className="title color-white">{counter.countersListTitle}</h3>
            </div>
        </div>
    ))
}

const CountersComponent = ({data}) => {
    return (
        <section className="position-relative parallax-bg text-center color-white section-regular" style={{backgroundImage: (data.countersBackground && data.countersBackground.length > 0) ? `url(${data.countersBackground[0].url})` : ""}}>
            <div className="container">
                <div className="row ptb-30">
                    <div className="col-lg-8 m-auto">
                        <div className="row">
                            {getCounters(data.countersList)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CountersComponent