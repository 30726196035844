const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const formatDate = (timestamp) => {
    let d = new Date(timestamp)
    return months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
}

const formatDateTime = (timestamp) => {
    let d = new Date(timestamp)
    return formatDate(timestamp) + " at " + (d.getHours() % 12 || 12) + (d.getHours() < 12 ? "AM" : "PM") + " (GMT-" + (d.getTimezoneOffset() / 60) + ")"
}

export {
    formatDate,
    formatDateTime
}