import * as React from "react"
import parse from "html-react-parser"
import "./hero.scss"

const getHeroBackground = (asset) => {
    switch(asset.kind) {
        case "video":
            return <video src={asset.url} loop muted autoPlay playsInline className="background" poster={(asset.videoPlaceholderImage && asset.videoPlaceholderImage.length > 0 ? asset.videoPlaceholderImage[0].url : null)}></video>
        case "image":
            return <img src={asset.url} alt={asset.alt} className="background" />
        default:
            return <></>
    }
}

const scrollDownHandler = (event) => {
    const currentSection = event.target.closest('section')
    if (!currentSection) { return false }

    const nextSection = currentSection.nextSibling
    if (!nextSection) { return false }

    nextSection.scrollIntoView( { behavior: 'smooth', block: 'start' } )
}

const HeroComponent = ({data}) => {
    switch(data.heroStyle) {
        case "style1":
            return (
                <section className="page-hero-section hero bg-video-wrap">
                    {data.heroBackground && data.heroBackground.length > 0 && getHeroBackground(data.heroBackground[0])}
                    <div className="overlay"></div>
                    <div className="hero-content-wrap">
                        <div className="hero-content">
                            {data.heroLogo && data.heroLogo.length > 0 &&
                                <img src={data.heroLogo[0].url} alt={data.heroLogo[0].alt} className="hero-logo d-none d-lg-block m-auto" />
                            }
                            {data.heroTitle && <h1>{data.heroTitle}</h1>}
                            {data.heroSubtitle && <span className="subhead">{data.heroSubtitle}</span>}
                            {data.heroBody &&
                                <div className="row pt-20 pb-30">
                                    <div className="col-12 col-lg-8 text-center m-auto button-anchor responsive-iframe">
                                        {parse(data.heroBody)}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {data.heroScrollDownButton && <button type="button" onClick={scrollDownHandler} className="scroll-down text-white" aria-label="Scroll Down"><i className="fa-solid fa-angle-down"></i></button>}
                </section>
            )
        case "style2":
            return (
                <section className="page-hero-section section-regular bg-white page-hero-section-bg">
                    <div className="container">
                        <div className="row pt-30 pb-20">
                            <div className="col-12">
                                <div className="hero-section-content text-center">
                                    {data.heroLogo && data.heroLogo[0] &&
                                        <div className="hero-image">
                                            <img src={data.heroLogo[0].url} alt={data.heroLogo[0].alt} />
                                        </div>
                                    }
                                    {data.heroTitle && <h1 className="f-midium">{data.heroTitle}</h1>}
                                    {data.heroSubtitle && <p className="mb-0 text-uppercase h1 border-0">{data.heroSubtitle}</p>}
                                </div>
                            </div>
                        </div>
                        {data.heroBody &&
                            <div className="row pt-20 pb-30">
                                <div className="col-12 col-lg-8 text-center m-auto button-anchor responsive-iframe">
                                    {parse(data.heroBody)}
                                </div>
                            </div>
                        }
                    </div>
                    {data.heroScrollDownButton && <button type="button" onClick={scrollDownHandler} className="scroll-down" aria-label="Scroll Down"><i className="fa-solid fa-angle-down"></i></button>}
                </section>
            )
        case "style3":
            return (
                <section className="page-hero-section section-regular bg-white section-shadow">
                    <div className="container">
                        <div className="row pt-30 pb-20">
                            <div className="col-12">
                                <div className="hero-section-content text-center">
                                    {data.heroLogo && data.heroLogo[0] &&
                                        <div className="hero-image">
                                            <img src={data.heroLogo[0].url} alt={data.heroLogo[0].alt} />
                                        </div>
                                    }
                                    {data.heroTitle && <h1 className="f-midium">{data.heroTitle}</h1>}
                                    {data.heroSubtitle && <p className="mb-0 text-uppercase h1 border-0">{data.heroSubtitle}</p>}
                                </div>
                            </div>
                        </div>
                        {data.heroBody &&
                            <div className="row pt-20 pb-30">
                                <div className="col-12 col-lg-8 text-center m-auto button-anchor responsive-iframe">
                                    {parse(data.heroBody)}
                                </div>
                            </div>
                        }
                    </div>
                    {data.heroScrollDownButton && <button type="button" onClick={scrollDownHandler} className="scroll-down" aria-label="Scroll Down"><i className="fa-solid fa-angle-down"></i></button>}
                </section>
            )
        default:
            return <></>
    }
}

export default HeroComponent