import * as React from "react"
import parse from "html-react-parser"

const TestimonialComponent = ({data}) => {
    return (
        <section className="testimonial bg-white position-relative">
            <div className="testimonial-background-pattern"></div>
            <div className="container">
                <div className="row py-30">
                    <div className="col-12">
                        <div className="testimonial-col text-center">
                            <div className="testimonial-content">
                                {parse(data.testimonialQuote)}
                            </div>
                            <div className="testimonial-meta">
                                {parse(data.testimonialSource)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonialComponent