import * as React from "react"
const getTeamMembers = (teamMembers) => {
    return teamMembers.map(teamMember => (
        <div key={teamMember.key} className="col">
            <div className="team-member">
                {teamMember.headshot && teamMember.headshot.length > 0 &&
                    <div className="team-thumb">
                        <img src={teamMember.headshot[0].url} width="156" height="156"
                             alt={teamMember.headshot[0].alt} />
                    </div>
                }
                <div className="team-member-discription">
                    <h4>{teamMember.fullName}</h4>
                    {teamMember.jobTitle && <p className="member-position">{teamMember.jobTitle}</p>}
                    {teamMember.linkedinUrl &&
                        <a href={teamMember.linkedinUrl} target="_blank" rel="noreferrer" aria-label="LinkedIn">
                            <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                    }
                </div>
            </div>
        </div>
    ))
}
const TeamComponent = ({data}) => {
    return (
        <section className="section-regular parallax-bg our-Founders" style={{backgroundImage: (data.teamBackground && data.teamBackground.length > 0) ? `url(${data.teamBackground[0].url})` : ""}}>
            <div className="container">
                <div className="row ptb-30">
                    <div className="col-lg-10 m-auto">
                        <div className="row">
                            <div className="col-lg-2">
                                <h2>{data.teamTitle}</h2>
                            </div>
                            <div className="col-lg-10">
                                <div className="row">
                                    {getTeamMembers(data.teamMembers)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamComponent