import * as React from "react"
import Layout from "../components/layout"
import LogoScrollerComponent from "../components/logoScroller"
import DividerComponent from "../components/divider"
import TestimonialComponent from "../components/testimonial"
import FeaturesGridComponent from "../components/featuresGrid"
import HeroComponent from "../components/hero"
import {graphql} from "gatsby"
import TwoColumnContentGridComponent from "../components/twoColumnContentGrid"
import CenteredContentComponent from "../components/centeredContent"
import IconFeatureListComponent from "../components/iconFeatureList";
import FlipFeatureListComponent from "../components/flipFeatureList";
import ContactUsFormComponent from "../components/contactUsForm";
import TypeformComponent from "../components/typeformComponent";
import CareerOpportunitiesComponent from "../components/careerOpportunities";
import SubscribeComponent from "../components/subscribe";
import ComicsComponent from "../components/comics";
import SectionLinksComponent from "../components/sectionLinks";
import PressReleasesUpcomingEventsComponent from "../components/pressReleasesUpcomingEvents";
import BackgroundImageComponent from "../components/backgroundImage";
import ProductSectionLinksComponent from "../components/productSectionLinks";
import StatisticsComponent from "../components/statistics";
import TwoColumnProductSectionLinksComponent from "../components/twoColumnProductSectionLinks";
import CalloutComponent from "../components/callout";
import TeamComponent from "../components/team";
import CountersComponent from "../components/counters";
import TabsComponent from "../components/tabs";
import HeaderScriptsComponent from "../components/headerScripts";
import BlogPostsComponent from "../components/blogPosts";
import PolicyComponent from "../components/policy";
import parse from "html-react-parser";

export const query = graphql`
    query ($uid: String) {
        entry: craftPagesDefaultEntry(uid: {eq: $uid}) {
            seomatic {
                ... on Craft_SeomaticType {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                    metaSiteVarsContainer
                }
            }
            title
            useSimpleHeaderAndFooter
            pageBody {
                __typename
                ... on Craft_pageBody_hero_BlockType {
                    heroStyle
                    heroBackground {
                        url
                        alt
                        kind
                        ... on Craft_images_Asset {
                            videoPlaceholderImage {
                                url
                            }
                        }
                    }
                    heroLogo {
                        url
                        alt
                    }
                    heroTitle
                    heroSubtitle
                    heroBody
                    heroScrollDownButton
                }
                ... on Craft_pageBody_logoScroller_BlockType {
                    logoScrollerImages {
                        key: uid
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_testimonial_BlockType {
                    testimonialBackground {
                        url
                    }
                    testimonialQuote
                    testimonialSource
                }
                ... on Craft_pageBody_featuresGrid_BlockType {
                    featuresGridTitle
                    featuresGridStyle
                    featuresGridBackground {
                        url
                        alt
                    }
                    featuresGridItems {
                        ... on Craft_featuresGridItems_BlockType {
                            key: uid
                            featuresGridItemLogo {
                                url
                                alt
                            }
                            featuresGridItemTitle
                            featuresGridItemIcon {
                                url
                                alt
                            }
                            featuresGridItemContent
                        }
                    }
                }
                ... on Craft_pageBody_divider_BlockType {
                    dividerStyle
                    dividerBackground {
                        url
                        alt
                    }
                    dividerTitle
                    dividerSubtitle
                    dividerShowRobot
                    dividerHeight
                }
                ... on Craft_pageBody_twoColumnContentGrid_BlockType {
                    twoColumnContentStyle
                    twoColumnContentBackground {
                        url
                        alt
                    }
                    twoColumnContentLeftColumnContent {
                        ... on Craft_twoColumnContentLeftColumnContent_BlockType {
                            twoColumnContentLeftColumnContentBackgroundImage {
                                url
                                alt
                            }
                            twoColumnContentLeftColumnContentBody
                        }
                    }
                    twoColumnContentRightColumnContent {
                        ... on Craft_twoColumnContentRightColumnContent_BlockType {
                            twoColumnContentRightColumnContentBackgroundImage {
                                url
                                alt
                            }
                            twoColumnContentRightColumnContentBody
                        }
                    }
                }
                ... on Craft_pageBody_centeredContent_BlockType {
                    centeredContentTitle
                    centeredContentSubtitle
                    centeredContentBody
                    centeredContentColumns
                    centeredContentBgAlignment
                    centeredContentBgImage {
                        url
                        alt
                    }
                    centeredContentBefore
                    centeredContentAfter
                }
                ... on Craft_pageBody_policy_BlockType {
                    policyTitle
                    policySubtitle
                    policyBody
                    policyColumns
                    policyBgAlignment
                    policyBgImage {
                        url
                        alt
                    }
                    policyBefore
                    policyAfter
                }
                ... on Craft_pageBody_iconFeaturesList_BlockType {
                    iconFeaturesListContentBefore
                    iconFeaturesListItems {
                        ... on Craft_iconFeaturesListItems_BlockType {
                            iconFeaturesListItemsIcon
                            iconFeaturesListItemsTitle
                            iconFeaturesListItemsContent
                        }
                    }
                    iconFeaturesListContentAfter
                    iconFeaturesListBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_flipFeaturesList_BlockType {
                    flipFeaturesListStyle
                    flipFeaturesListContentBefore
                    flipFeaturesListItems {
                        ... on Craft_flipFeaturesListItems_BlockType {
                            key: uid
                            flipFeaturesListItemsIcon
                            flipFeaturesListItemsTitle
                            flipFeaturesListItemsContent
                            flipFeaturesListItemsImage {
                                url
                                alt
                            }
                        }
                    }
                    flipFeaturesListContentAfter
                    flipFeaturesListBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_contactUsForm_BlockType {
                    contactUsFormSectionTitle
                    contactUsFormContent
                    contactUsFormTitle
                    contactUsFormBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_typeform_BlockType {
                    typeformSource
                }
                ... on Craft_pageBody_careerOpportunities_BlockType {
                    careerOpportunitiesBefore
                    careerOpportunitiesFeatured {
                        ... on Craft_careers_default_Entry {
                            key: uid
                            title
                            url
                            careerDescription
                            careerRequirements
                        }
                    }
                    careerOpportunitiesAfter
                }
                ... on Craft_pageBody_backgroundImage_BlockType {
                    backgroundImageImage {
                        url
                        alt
                    }
                    backgroundImageStyle
                }
                ... on Craft_pageBody_subscribe_BlockType {
                    subscribeTitle
                    subscribeContent
                }
                ... on Craft_pageBody_comics_BlockType {
                    comicsComicStrips {
                        url
                        alt
                    }
                    comicsNewestTitle
                    comicsNewestContent
                    comicsFavoriteTitle
                    comicsFavoriteContent
                    comicsFavoriteComicStrip {
                        url
                        alt
                    }
                    comicsBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_sectionLinks_BlockType {
                    sectionLinksStyle
                    sectionLinksContentBefore
                    sectionLinkSections {
                        ... on Craft_sectionLinkSections_BlockType {
                            sectionLinkSectionsTitle
                            sectionLinkSectionsContent
                        }
                    }
                    sectionLinksContentAfter
                    sectionLinksBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_pressReleasesUpcomingEvents_BlockType {
                    pressRelPressTitle
                    pressRelEventsTitle
                    pressRelPressEmpty
                    pressRelEventsEmpty
                    pressRelEventsBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_blogPosts_BlockType {
                    blogPostsEmpty
                    blogPostsBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_productSectionLinks_BlockType {
                    productSectionLinksEnabled
                }
                ... on Craft_pageBody_statistics_BlockType {
                    statisticsEnabled
                }
                ... on Craft_pageBody_callout_BlockType {
                    calloutList {
                        ... on Craft_calloutList_BlockType {
                            calloutListTitle
                            calloutListContent
                        }
                    }
                    calloutBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_team_BlockType {
                    teamTitle
                    teamMembers {
                        ... on Craft_User {
                            key: uid
                            fullName
                            headshot {
                              url
                              alt
                            }
                            jobTitle
                            linkedinUrl
                        }
                    }
                    teamBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_counters_BlockType {
                    countersList {
                        ... on Craft_countersList_BlockType {
                            countersListCount
                            countersListTitle
                        }
                    }
                    countersBackground {
                        url
                        alt
                    }
                }
                ... on Craft_pageBody_tabs_BlockType {
                    tabsList {
                        ... on Craft_tabsList_BlockType {
                            key: uid
                            tabsListTitle
                            tabsListContent
                        }
                    }
                    tabsBackground {
                        url
                        alt
                    }
                    tabsContentBefore
                    tabsContentAfter
                }
            }
        }
    }
`
const PageTemplate = ({data}) => {
    return (
        <Layout seomatic={data.entry.seomatic} useSimpleHeaderAndFooter={data.entry.useSimpleHeaderAndFooter}>

            {data.entry.pageBody.map((block, index) => {
                switch(block.__typename) {
                    case "Craft_pageBody_hero_BlockType":
                        return <HeroComponent data={block} key={index}/>
                    case "Craft_pageBody_logoScroller_BlockType":
                        return <LogoScrollerComponent data={block} key={index}/>
                    case "Craft_pageBody_testimonial_BlockType":
                        return <TestimonialComponent data={block} key={index}/>
                    case "Craft_pageBody_featuresGrid_BlockType":
                        return <FeaturesGridComponent data={block} key={index}/>
                    case "Craft_pageBody_divider_BlockType":
                        return <DividerComponent data={block} key={index}/>
                    case "Craft_pageBody_twoColumnContentGrid_BlockType":
                        return <TwoColumnContentGridComponent data={block} key={index}/>
                    case "Craft_pageBody_centeredContent_BlockType":
                        return <CenteredContentComponent data={block} key={index}/>
                    case "Craft_pageBody_policy_BlockType":
                        return <PolicyComponent data={block} key={index}/>
                    case "Craft_pageBody_iconFeaturesList_BlockType":
                        return <IconFeatureListComponent data={block} key={index}/>
                    case "Craft_pageBody_flipFeaturesList_BlockType":
                        return <FlipFeatureListComponent data={block} key={index}/>
                    case "Craft_pageBody_contactUsForm_BlockType":
                        return <ContactUsFormComponent data={block} key={index}/>
                    case "Craft_pageBody_typeform_BlockType":
                        return <TypeformComponent data={block} key={index}/>
                    case "Craft_pageBody_careerOpportunities_BlockType":
                        return <CareerOpportunitiesComponent data={block} key={index}/>
                    case "Craft_pageBody_subscribe_BlockType":
                        return <SubscribeComponent data={block} key={index}/>
                    case "Craft_pageBody_comics_BlockType":
                        return <ComicsComponent data={block} key={index}/>
                    case "Craft_pageBody_sectionLinks_BlockType":
                        return <SectionLinksComponent data={block} key={index}/>
                    case "Craft_pageBody_pressReleasesUpcomingEvents_BlockType":
                        return <PressReleasesUpcomingEventsComponent data={block} key={index}/>
                    case "Craft_pageBody_blogPosts_BlockType":
                        return <BlogPostsComponent data={block} key={index}/>
                    case "Craft_pageBody_backgroundImage_BlockType":
                        return <BackgroundImageComponent data={block} key={index}/>
                    case "Craft_pageBody_productSectionLinks_BlockType":
                        return <ProductSectionLinksComponent data={block} key={index}/>
                    case "Craft_pageBody_statistics_BlockType":
                        return <StatisticsComponent data={block} key={index}/>
                    case "Craft_pageBody_twoColumnProductSectionLinks_BlockType":
                        return <TwoColumnProductSectionLinksComponent data={block} key={index}/>
                    case "Craft_pageBody_callout_BlockType":
                        return <CalloutComponent data={block} key={index}/>
                    case "Craft_pageBody_team_BlockType":
                        return <TeamComponent data={block} key={index}/>
                    case "Craft_pageBody_counters_BlockType":
                        return <CountersComponent data={block} key={index}/>
                    case "Craft_pageBody_tabs_BlockType":
                        return <TabsComponent data={block} key={index}/>
                    default:
                        return <></>
                }
            })}
        </Layout>
    )
}

export default PageTemplate

export const Head = ({data}) => (
    <HeaderScriptsComponent data={data} />
)