import * as React from "react"
import parse from "html-react-parser"

const ComicsComponent = ({data}) => {
    const getComics = () => {
        return data.comicsComicStrips.map(comic => (
            <div className="row pb-2 pt-2">
                <div className="col-12">
                    <div className="image-inner text-center">
                        <img src={comic.url} alt={comic.alt} />
                    </div>
                </div>
            </div>
        ))
    }
    return (
        <section className="position-relative parallax-bg p-5"  style={{backgroundImage: (data.comicsBackground && data.comicsBackground.length > 0) ? `url(${data.comicsBackground[0].url})` : ""}}>
            <div className="container iframe-blue-border">
                <div className="row pb-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="modios-content-block">
                            <div className="image-inner text-center">
                                <img src={data.comicsComicStrips[data.comicsComicStrips.length-1].url} alt={data.comicsComicStrips[0].alt} />
                            </div>
                            <div className="modios-description">
                                <h4 className="text-white">{data.comicsNewestTitle}</h4>
                                {parse(data.comicsNewestContent)}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="modios-content-block">
                            <div className="image-inner text-center">
                                <img src={data.comicsFavoriteComicStrip[0].url} alt={data.comicsFavoriteComicStrip[0].alt} />
                            </div>
                            <div className="modios-description mobile-m-0">
                                <h4 className="text-white">{data.comicsFavoriteTitle}</h4>
                                {parse(data.comicsFavoriteContent)}
                            </div>
                        </div>
                    </div>
                </div>
                {getComics()}
            </div>
        </section>
    )
}

export default ComicsComponent