import * as React from "react"
import {formatDate, formatDateTime} from "../helpers/date";
import { StaticQuery, graphql } from "gatsby"
import parse from "html-react-parser";

const PressReleasesUpcomingEventsComponent = ({data}) => {

    const getEvents = (events) => {
        if (events.nodes.length > 0) {
            return events.nodes.map(event => (
                <div className="press-box">
                    <a href={`/${event.uri}`} className="text-black">
                        <h4>{event.title}</h4>
                    </a>
                    <p className="post-meta"><span className="published">{formatDateTime(event.eventEventDate)}</span></p>
                    <div className="post-content">
                        <div className="post-content-inner">
                            <p>{event.eventPreviewText}</p>
                        </div>
                    </div>
                </div>
            ))
        } else {
            return (
                <span className="text-center color-white">{parse(data.pressRelEventsEmpty)}</span>
            )
        }
    }

    const getPressReleases = (pressReleases) => {
        if (pressReleases.nodes.length > 0) {
            return pressReleases.nodes.map(pressRelease => (
                <div className="press-box">
                    <a href={`/${pressRelease.uri}`} className="text-black">
                        <h4>{pressRelease.title}</h4>
                    </a>
                    <p className="post-meta"><span className="published">{formatDate(pressRelease.postDate)}</span></p>
                    <div className="post-content">
                        <div className="post-content-inner">
                            <p>{pressRelease.pressReleasePreviewText}</p>
                        </div>
                    </div>
                </div>
            ))
        } else {
            return (
                <span className="text-center color-white">{parse(data.pressRelPressEmpty)}</span>
            )
        }
    }

    return (
        <StaticQuery
            query={graphql`{
                        pressReleases: allCraftPressReleasesDefaultEntry {
                            nodes {
                                key: uid
                                title
                                uri
                                postDate
                                pressReleasePreviewText
                            }
                        }
                        events: allCraftEventsDefaultEntry {
                            nodes {
                                key: uid
                                title
                                uri
                                eventEventDate
                                eventPreviewText
                            }
                        }
                    }`}
            render={staticData => (
                <section className="position-relative parallax-bg pt-100 pb-100 press-events-section" style={{backgroundImage: (data.pressRelEventsBackground && data.pressRelEventsBackground.length > 0) ? `url(${data.pressRelEventsBackground[0].url})` : ""}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <h2>{data.pressRelPressTitle}</h2>
                                {getPressReleases(staticData.pressReleases)}
                            </div>
                            <div className="col-sm-12 col-lg-6 upcomming-event">
                                <h2>{data.pressRelEventsTitle}</h2>
                                {getEvents(staticData.events)}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        />
    )
}

export default PressReleasesUpcomingEventsComponent