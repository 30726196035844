import * as React from "react"

const BackgroundImageComponent = ({data}) => {
    switch(data.backgroundImageStyle) {
        case "style1":
            return (
                <section className="position-relative">
                    <div className="parallax-bg-height" style={{backgroundImage: `url(${data.backgroundImageImage[0].url})`}}></div>
                    <div className="modios-presenting-from-cover">
                        <img src="https://assets.cordiance.com/craft/modios-presenting-from-cover.png" width="200" height="245" alt=""/>
                    </div>
                </section>
            )
        case "style2":
            return (
                <section className="position-relative">
                    <div className="parallax-bg-height-sm" style={{backgroundImage: `url(${data.backgroundImageImage[0].url})`}}></div>
                </section>
            )
        default:
            return <></>
    }
}

export default BackgroundImageComponent