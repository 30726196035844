import * as React from "react"

const TypeformComponent = ({data}) => {
    return (
        <iframe
            src={data.typeformSource}
            allow="camera; microphone; autoplay; encrypted-media; fullscreen;" data-qa="iframe" frameBorder="0" height="100%"
            title="typeform-embed" width="100%" style={{height: 'calc(100vh - 64px)'}}></iframe>
    )
}

export default TypeformComponent