import * as React from "react"
import parse from "html-react-parser"
import "./sectionLinks.scss"

const SectionLinksComponent = ({data}) => {
    const getStyle1Sections = () => {
        return data.sectionLinkSections.map((section, index) => (
            <div className="col-sm-12 col-md-12 col-lg-6 text-center m-auto p-4">
                <div className={`${index % 2 === 0 ? 'blue-iframe' : 'pink-iframe'} text-white h2-size-43 button-anchor`}>
                    {section.sectionLinkSectionsTitle &&
                        <h2 className="text-white">{section.sectionLinkSectionsTitle}</h2>
                    }
                    {section.sectionLinkSectionsContent && parse(section.sectionLinkSectionsContent)}
                </div>
            </div>
        ))
    }

    const getStyle2Sections = () => {
        return data.sectionLinkSections.map((section, index) => (
            <div className="col-sm-12 col-md text-center m-auto p-5 section-links">
                <div className="h2-size-43">
                    {section.sectionLinkSectionsTitle &&
                        <h2>{section.sectionLinkSectionsTitle}</h2>
                    }
                    {section.sectionLinkSectionsContent && parse(section.sectionLinkSectionsContent)}
                </div>
            </div>
        ))
    }

    switch(data.sectionLinksStyle) {
        case "style1":
            return (
                <section className="section-links-block position-relative parallax-bg style1"
                         style={{backgroundImage: (data.sectionLinksBackground && data.sectionLinksBackground.length > 0) ? `url(${data.sectionLinksBackground[0].url})` : ""}}>
                    <div className="container pt-50 pb-90">
                        {data.sectionLinksContentBefore &&
                            <div className="row mobile-py-20">
                                <div className="col-12 col-lg-12 text-center m-auto iframe-100">
                                    {parse(data.sectionLinksContentBefore)}
                                </div>
                            </div>
                        }
                        <div className="row pt-50 mobile-py-20">
                            {getStyle1Sections()}
                        </div>
                        {data.sectionLinksContentAfter &&
                            <div className="row mobile-py-20 pt-50">
                                <div>
                                    {parse(data.sectionLinksContentAfter)}
                                </div>
                            </div>
                        }
                    </div>
                </section>
            )
        case "style2":
            return (
                <section className="section-links-block position-relative parallax-bg style2"
                         style={{backgroundImage: (data.sectionLinksBackground && data.sectionLinksBackground.length > 0) ? `url(${data.sectionLinksBackground[0].url})` : ""}}>
                    <div className="container pt-90 pb-50">
                        {data.sectionLinksContentBefore &&
                            <div className="row mobile-py-20">
                                <div className="col-12 col-lg-12 text-center m-auto iframe-100 color-pink">
                                    {parse(data.sectionLinksContentBefore)}
                                </div>
                            </div>
                        }
                        <div className="row pt-50 mobile-py-20">
                            {getStyle2Sections()}
                        </div>
                        {data.sectionLinksContentAfter &&
                            <div className="row mobile-py-20 pt-50">
                                <div>
                                    {parse(data.sectionLinksContentAfter)}
                                </div>
                            </div>
                        }
                    </div>
                </section>
            )
        default:
            return <></>
    }
}

export default SectionLinksComponent