import * as React from "react"
import parse from "html-react-parser"
import { StaticQuery, graphql } from "gatsby"

const CareerOpportunitiesComponent = ({data}) => {
    const renderOpportunity = (opportunity) => (
        <div key={opportunity.key} className="col-lg-6 text-left mb-4">
            <div className="box-with-border">
                <h3>{opportunity.title}</h3>
                {parse(opportunity.careerDescription)}
                <a href={`/${opportunity.uri}`}>More Info</a>
            </div>
        </div>
    )

    const getOpportunities = () => {
        if (data.careerOpportunitiesFeatured && data.careerOpportunitiesFeatured.length > 0) {
            return data.careerOpportunitiesFeatured.map(renderOpportunity)
        } else {
            return (
                <StaticQuery
                    query={graphql`{
                        opportunities: allCraftCareersDefaultEntry {
                            nodes {
                              key: uid
                              title
                              uri
                              careerDescription
                              careerRequirements
                            }
                          }
                        }
                    `}
                    render={data => {
                        if (data.opportunities && data.opportunities.nodes && data.opportunities.nodes.length > 0) {
                            return data.opportunities.nodes.map(renderOpportunity)
                        } else {
                            return <></>
                        }
                    }}
                />
            )
        }
    }

    return (
        <section className="section-regular page-hero-section-bg page-hero-section-bg-scale">
            <div className="container">
                <div className="row ptb-30">
                    <div className="col-12 text-center">
                        <h2>Openings</h2>
                    </div>
                </div>
                <div className="row ptb-30">
                    {getOpportunities()}
                </div>
                <div className="row ptb-30">
                    <div className="col-12 text-center">
                        <h2>Human Resources</h2>
                        <p>We’re always looking for the best and the brightest to join our team.</p>
                        <p>Please submit resumes (and cover letters if you feel like standing out) to <a
                            href="mailto:info@cordiance.com">info@cordiance.com</a>.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CareerOpportunitiesComponent