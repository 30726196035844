import * as React from "react"
import parse from "html-react-parser"

const getCallouts = (callouts) => {
    return callouts.map(callout => (
        <div className="row ptb-30">
            <div className="col-lg-10 m-auto">
                <div className="row">
                    <div className="col-lg-4">
                        <h2>{callout.calloutListTitle}</h2>
                    </div>
                    <div className="col-lg-8">
                        {parse(callout.calloutListContent)}
                    </div>
                </div>
            </div>
        </div>
    ))
}

const CalloutComponent = ({data}) => {
    return (
        <section className="section-regular page-hero-section-bg expertise-section" style={{backgroundImage: (data.calloutBackground && data.calloutBackground.length > 0) ? `url(${data.calloutBackground[0].url})` : ""}}>
            <div className="container">
                {getCallouts(data.calloutList)}
            </div>
        </section>
    )
}

export default CalloutComponent