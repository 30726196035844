import * as React from "react"
import parse from "html-react-parser"
import "./twoColumnContentGrid.scss"

const TwoColumnContentGridComponent = ({data}) => {
    switch(data.twoColumnContentStyle) {
        case "style1":
            return (
                <section className="bigcard-box-section two-column-content-grid-component style-1" style={{backgroundImage: (data.twoColumnContentBackground && data.twoColumnContentBackground.length > 0) ? `linear-gradient(180deg,rgba(51,50,54,0.76) 0%,rgba(51,50,54,0.63) 100%), url(${data.twoColumnContentBackground[0].url})` : ""}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 p-0">
                                <div
                                    className="bigcard-box d-flex flex-column bg-darkbg position-relative parallax-content text-center button-anchor responsive-iframe">
                                    <div className="parallax-window"
                                         style={{backgroundImage: `url(${(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage && data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0]) ? data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0].url : ''})`}}>
                                        <div className="bigcard-box-inner">
                                            {data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody &&
                                                parse(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div
                                    className="bigcard-box d-flex flex-column bg-darkbg position-relative parallax-content text-center button-anchor responsive-iframe">
                                    <div className="parallax-window"
                                         style={{backgroundImage: `url(${(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage && data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0]) ? data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0].url : ''})`}}>
                                        <div className="bigcard-box-inner">
                                            {data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody &&
                                                parse(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        case "style2":
            return (
                <section className="bg-dark position-relative two-column-content-grid-component style-2" style={{backgroundImage: (data.twoColumnContentBackground && data.twoColumnContentBackground.length > 0) ? `linear-gradient(180deg,rgba(51,50,54,0.76) 0%,rgba(51,50,54,0.63) 100%), url(${data.twoColumnContentBackground[0].url})` : ""}}>
                    <div className="container-fluid">
                        <div className="row">
                            {data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody ?
                                <div className="col-lg-6 p-0">
                                    <div className="dark-bg-box p-l-height h2-size-43 button-anchor responsive-iframe">
                                        {data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody &&
                                            parse(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody)
                                        }
                                    </div>
                                </div>
                                :
                                <div className="col-md-6 p-0 divImg d-none d-lg-flex" style={{
                                    //backgroundImage: `linear-gradient(180deg,rgba(198,25,127,0.45) 0%,rgba(51,50,54,0) 100%),url(${(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage && data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0]) ? data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0].url : ''})`
                                    backgroundImage: `url(${(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage && data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0]) ? data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0].url : ''})`
                                }}></div>
                            }
                            
                            {data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody ?
                                <div className="col-lg-6 p-0">
                                    <div className="dark-bg-box p-l-height h2-size-43 button-anchor responsive-iframe">
                                        {data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody &&
                                            parse(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody)
                                        }
                                    </div>
                                </div>
                            :
                                <div className="col-md-6 p-0 divImg d-none d-lg-flex" style={{
                                    //backgroundImage: `linear-gradient(180deg,rgba(198,25,127,0.45) 0%,rgba(51,50,54,0) 100%),url(${(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage && data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0]) ? data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0].url : ''})`
                                    backgroundImage: `url(${(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage && data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0]) ? data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0].url : ''})`
                                }}></div>
                            }
                        </div>
                    </div>
                </section>
            )
        case "style3":
            return (
                <section className="section-regular modios-professional-network color-white two-column-content-grid-component style-3" style={{backgroundImage: (data.twoColumnContentBackground && data.twoColumnContentBackground.length > 0) ? `linear-gradient(180deg,rgba(51,50,54,0.76) 0%,rgba(51,50,54,0.63) 100%),url(${data.twoColumnContentBackground[0].url})` : ""}}>
                    <div className="container">
                        <div className="row ptb-30">
                            {data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody ?
                                <div className="col-lg-6 text-center button-anchor pe-lg-5">
                                    {data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody &&
                                        parse(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody)
                                    }
                                </div>
                                :
                                <div className="col-md-6 p-0 divImg d-none d-lg-flex" style={{
                                    backgroundImage: `linear-gradient(180deg,rgba(198,25,127,0.45) 0%,rgba(51,50,54,0) 100%),url(${(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage && data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0]) ? data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0].url : ''})`
                                }}></div>
                            }
                            {data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody ?
                                <div className="col-lg-6 button-anchor pt-5 pt-lg-0 ps-lg-5">
                                    {data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody &&
                                        parse(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody)
                                    }
                                </div>
                                :
                                <div className="col-md-6 p-0 divImg d-none d-lg-flex" style={{
                                    backgroundImage: `linear-gradient(180deg,rgba(198,25,127,0.45) 0%,rgba(51,50,54,0) 100%),url(${(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage && data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0]) ? data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0].url : ''})`
                                }}></div>
                            }
                        </div>
                    </div>
                </section>
            )
        case "style4":
            return (
                <section className="section-regular two-column-content-grid-component style-4 page-hero-section-bg" style={{backgroundImage: (data.twoColumnContentBackground && data.twoColumnContentBackground.length > 0) ? `linear-gradient(180deg,rgba(51,50,54,0.76) 0%,rgba(51,50,54,0.63) 100%),url(${data.twoColumnContentBackground[0].url})` : ""}}>
                    <div className="container">
                        <div className="row ptb-30">
                            {data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody ?
                                <div className="col-lg-6 text-center button-anchor pe-lg-5 text-black">
                                    {data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody &&
                                        parse(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBody)
                                    }
                                </div>
                                :
                                <div className="col-md-6 p-0 divImg d-none d-lg-flex" style={{
                                    backgroundImage: `linear-gradient(180deg,rgba(198,25,127,0.45) 0%,rgba(51,50,54,0) 100%),url(${(data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage && data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0]) ? data.twoColumnContentLeftColumnContent[0].twoColumnContentLeftColumnContentBackgroundImage[0].url : ''})`
                                }}></div>
                            }
                            {data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody ?
                                <div className="col-lg-6 button-anchor pt-5 pt-lg-0 ps-lg-5 text-black">
                                    {data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody &&
                                        parse(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBody)
                                    }
                                </div>
                                :
                                <div className="col-md-6 p-0 divImg d-none d-lg-flex" style={{
                                    backgroundImage: `linear-gradient(180deg,rgba(198,25,127,0.45) 0%,rgba(51,50,54,0) 100%),url(${(data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage && data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0]) ? data.twoColumnContentRightColumnContent[0].twoColumnContentRightColumnContentBackgroundImage[0].url : ''})`
                                }}></div>
                            }
                        </div>
                    </div>
                </section>
            )
        default:
            return <></>
    }
}

export default TwoColumnContentGridComponent