import * as React from "react"
import parse from "html-react-parser"
import "./featuresGrid.scss"

const FeaturesGridComponent = ({data}) => {
    switch(data.featuresGridStyle) {
        case "style1":
            return (
                <section className="features-grid pt-50 pb-100 pulse position-relative parallax-bg" style={{backgroundImage: (data.featuresGridBackground && data.featuresGridBackground.length > 0) ? `url(${data.featuresGridBackground[0].url})` : ""}}>
                    {data.featuresGridTitle &&
                        <div className="container pt-100">
                            <div className="row pt-50 pb-100">
                                <div className="col-12 text-center title button-anchor">
                                    {parse(data.featuresGridTitle)}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            {getStyle1Items(data.featuresGridItems)}
                        </div>
                    </div>
                </section>
            )
        case "style2":
            return (
                <section className="position-relative parallax-bg color-white section-regular" style={{backgroundImage: (data.featuresGridBackground && data.featuresGridBackground.length > 0) ? `url(${data.featuresGridBackground[0].url})` : ""}}>
                    <div className="container">
                        <div className="row ptb-30">
                            {getStyle2Items(data.featuresGridItems)}
                        </div>
                    </div>
                </section>
            )
        default:
            return <></>
    }
}

const getStyle1Items = (items) => {
    return items.map(item => (
        <div key={item.key} className="col-12 col-md-4 features-grid-item">
            <div className="col-wrap text-center">
                {item.featuresGridItemLogo && item.featuresGridItemLogo[0] &&
                    <div className="image-wrap">
                        <img src={item.featuresGridItemLogo[0].url} alt={item.featuresGridItemLogo[0].alt} width="400" height="41" />
                    </div>
                }

                <h3>{parse(item.featuresGridItemTitle)}</h3>

                {item.featuresGridItemIcon && item.featuresGridItemIcon[0] &&
                    <div className="image-icon-wrap">
                        <img src={item.featuresGridItemIcon[0].url} alt={item.featuresGridItemIcon[0].alt} width="124" height="125" />
                    </div>
                }

                <div className="col-wrap-bottom button-anchor">
                    {parse(item.featuresGridItemContent)}
                </div>
            </div>
        </div>
    ))
}

const getStyle2Items = (items) => {
    return items.map(item => (
        <div key={item.key} className="col-lg-4">
            {item.featuresGridItemLogo && item.featuresGridItemLogo[0] &&
                <div className="brands-logo">
                    <img src={item.featuresGridItemLogo[0].url} alt={item.featuresGridItemLogo[0].alt} width="300" height="300"/>
                </div>
            }
            <div className="brands-discription">
                <h4>{parse(item.featuresGridItemTitle)}</h4>
                {parse(item.featuresGridItemContent)}
            </div>
        </div>
    ))
}

export default FeaturesGridComponent