import * as React from "react"

const ProductSectionLinksComponent = ({data}) => {
    return (
        <section className="section-regular real-intelligence bg-dark">
            <div className="container">
                <div className="row align-items-center ptb-30">
                    <div className="col-md-7">
                        <div className="modios-nextgen-wrap">
                            <img src="https://assets.cordiance.com/craft/modios-nextgen-dk-small.png" width="400" height="41" alt=""/>
                        </div>
                        <h5>Real Intelligence For All Your Indirect Tax Needs</h5>
                    </div>
                    <div className="col-md-5 text-end">
                        <a className="btn btn-primary" href="/contact-us/customer-contact-request">Schedule a Demo</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="all-page-links-row ptb-30 d-flex align-items-center">
                            <div className="all-page-links-col">
                                <a href="/modios/tax-engine-evaluation/tax-engine-testing" className="parent-link text-white" target="_self"
                                   alt="Evaluate &amp; Inform" rel="noopener">Pulse: Evaluate &amp; Inform</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/tax-engine-evaluation/tax-engine-testing">Rapid Testing</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/tax-engine-evaluation/tax-engine-analysis">Rapid Insight</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/tax-engine-evaluation/rapid-insight-plus">Rapid Insight +</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/tax-engine-evaluation/rapid-verify">RAPID VERIFY</a>
                            </div>
                        </div>
                        <div className="d-flex separator align-items-center">
                            <span className="line-separator line-separator-left"></span>
                            <span className="separator-icon"><i className="fa fa-chart-line"></i></span>
                            <span className="line-separator line-separator-right"></span>
                        </div>
                        <div className="all-page-links-row ptb-30 d-flex align-items-center">
                            <div className="all-page-links-col">
                                <a href="/modios/implement-and-manage-your-own-tax-engine" className="parent-link text-white" target="_self"
                                   alt="Evaluate &amp; Inform" rel="noopener">Implement & Manage</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/implement-and-manage-your-own-tax-engine/tax-engine-implementation">Rapid Implement</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/implement-and-manage-your-own-tax-engine/tax-engine-migration">Rapid Migrate</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/implement-and-manage-your-own-tax-engine/modios-rapid-expand">Rapid Expand</a>
                            </div>
                        </div>
                        <div className="d-flex separator align-items-center">
                            <span className="line-separator line-separator-left"></span>
                            <span className="separator-icon"><i className="fa fa-chart-line"></i></span>
                            <span className="line-separator line-separator-right"></span>
                        </div>
                        <div className="all-page-links-row ptb-30 d-flex align-items-center">
                            <div className="all-page-links-col">
                                <a href="/modios/optimize-and-maintain-your-tax-engine" className="parent-link text-white" target="_self"
                                   alt="Evaluate &amp; Inform" rel="noopener">OPTIMIZE & MAINTAIN</a>
                            </div>
                            <div className="all-page-links-col">
                                <a className="btn btn-secondary" href="/modios/optimize-and-maintain-your-tax-engine/tax-engine-product-mapping">Rapid Mapping</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductSectionLinksComponent