import * as React from "react"
import parse from "html-react-parser"

const IconFeatureListComponent = ({data}) => {
    const getFeatures = (featureItems) => {
        return featureItems.map((item, index) => (
            <div className="col-12 col-md-6 col-lg">
                <div className="white-icon-set">
                    {item.iconFeaturesListItemsIcon &&
                        <div className="icon">
                            <span aria-hidden="true" className={item.iconFeaturesListItemsIcon}></span>
                        </div>
                    }
                    {item.iconFeaturesListItemsTitle && <h4>{item.iconFeaturesListItemsTitle}</h4>}
                    {item.iconFeaturesListItemsContent && parse(item.iconFeaturesListItemsContent)}
                </div>
            </div>
        ))
    }

    return (
        <section className="position-relative parallax-bg text-center color-white section-regular" style={{backgroundImage: (data.iconFeaturesListBackground && data.iconFeaturesListBackground.length > 0) ? `url(${data.iconFeaturesListBackground[0].url})` : ""}}>
            <div className="container">
                <div className="row ptb-30">
                    <div className="col-12 text-center text-white">
                        {data.iconFeaturesListContentBefore && parse(data.iconFeaturesListContentBefore)}
                    </div>
                </div>
                <div className="row ptb-30">
                    {getFeatures(data.iconFeaturesListItems)}
                </div>
                <div className="row ptb-30">
                    <div className="col-12 text-center text-white">
                        {data.iconFeaturesListContentAfter && parse(data.iconFeaturesListContentAfter)}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IconFeatureListComponent